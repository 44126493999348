/* eslint-disable vue/no-side-effects-in-computed-properties */
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import moment from 'moment';

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout},
  data() {
    return {
      title: "",
      firstname : "",
      lastname : "",
      email : "",
      password : "",
      currentpage : 1,
      selectedPackage : "",
      selectedStatus : "",
      optionsStatus : [
          {
              value : "",
              text : "All"
          },
          {
              value : 1,
              text : "Active"
          },
          {
              value : 0,
              text : "Suspend"
          }
      ],
      reason : ""
    }
  },
  computed: {
    loading(){
        return this.$store.getters['users/loading'];
    },
    loadingInvoice(){
        return this.$store.getters['payments/loading'];
    },
    loadingSub(){
        return this.$store.getters['payments/loadingSub'];
    },
    user(){
        return this.$store.getters['users/user'];
    },
    invoices(){
        return this.$store.getters['payments/invoices'];
    },
    subscriptions(){
        return this.$store.getters['payments/subscriptions'];
    },
  },
  created(){
  },
  mounted(){
      this.$store.dispatch("users/detail",this.$route.params.id)
      .then((user)=>{
          this.$store.dispatch("payments/listing",user.email)
          this.$store.dispatch("payments/subscriptions",user.email)
      })
  },
  methods : {
    parseDate(date){
        return moment(date).format('llll')
    },
    parseTimeStamp(stamp){
        return moment.unix(stamp).format('llll')
    },
    fromNow(date){
        return moment(date).toNow(true)
    },
    convertBytes(bytes) {
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"]

        if (bytes == 0) {
            return "0 Bytes"
        }

        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

        if (i == 0) {
            return bytes + " " + sizes[i]
        }

        return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i]
    },
    moreInvoice(email){
        this.$store.commit("payments/SET_LIMIT",this.$store.getters['payments/limit']+10);
        this.$store.dispatch("payments/listing",email)
    },
    moreSubcription(email){
        this.$store.commit("payments/SET_LIMITSUB",this.$store.getters['payments/limitSub']+10);
        this.$store.dispatch("payments/subscriptions",email)
    },
    okeSuspend(){
        if(this.reason == ""){
            this.$toast.open({
                message: "reason empty",
                type: 'error',
            });
        }else{
            this.$store.dispatch('users/suspend',{id:this.$route.params.id,reason:this.reason})
        }
    },
    active(){
            this.$store.dispatch('users/active',this.$route.params.id)
    }
  }

};
</script>

<template>
  <Layout>
    <div class="row" v-if="user != null">
      <div class="col-xl-4">

        <b-overlay :show="loading" rounded="sm">
            <div class="card overflow-hidden " with overlay :aria-hidden="loading ? 'true' : null">
            <div class="bg-soft-primary">
                <div class="row">
                <div class="col-7">
                    <div class="text-primary p-3">
                    <h5 class="text-primary">Jaybod User</h5>
                    <p>{{fromNow(user.date_join)}} active on jaybod</p>
                    </div>
                </div>
                <div class="col-5 align-self-end">
                    <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
                </div>
                </div>
            </div>
            <div class="card-body pt-0">
                <div class="row justify-content-md-center">
                    <div class="d-flex justify-content-center align-items-center flex-column">
                        <div class="avatar-md profile-user-wid mb-4">
                        <img
                            src="@/assets/images/users/avatar-1.jpg"
                            alt
                            class="img-thumbnail rounded-circle"
                        />
                        </div>
                        <div class="d-flex justify-content-center align-items-center flex-column">
                            <h5 class="font-size-15 ">{{user.firstname}} {{user.lastname}}</h5>
                            <p class="text-muted mb-0 ">{{user["rgw.main"] ? convertBytes(user["rgw.main"].size_actual) : '0 bytes'}} / {{convertBytes(user.max_size / 2)}} Usage</p>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between  ">
                                <router-link tag="a" to="/users" class=" mx-2 btn btn-danger btn-sm" v-b-tooltip.hover title="tonton user yg lain"> <i class="bx bx-left-arrow-alt"></i> Back</router-link>
                    <button v-if="user.isActive" class="btn btn-warning btn-sm" v-b-modal.suspend v-b-tooltip.hover title="matiin aja, gk tau diri dia"> <i class="bx bx-power-off"></i> Suspend</button>
                    <button v-else @click="active()" class="btn btn-primary btn-sm" v-b-tooltip.hover title="yah di aktifin, nyusahin dia mah"> <i class="bx bx-plug"></i> Actived</button>
                </div> 
            </div>
            </div>
        </b-overlay>
        <!-- end card -->

        <b-overlay :show="loading" rounded="sm">
            <div class="card"  with overlay :aria-hidden="loading ? 'true' : null">
            <div class="card-body">
                <h4 class="card-title mb-4">Personal Information</h4>
                <div class="table-responsive mb-0">
                <table class="table">
                    <tbody>
                    <tr>
                        <th scope="row">ID :</th>
                        <td>{{user._id}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Firstname :</th>
                        <td>{{user.firstname}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Lastname :</th>
                        <td>{{user.lastname}}</td>
                    </tr>
                    <tr>
                        <th scope="row">E-mail :</th>
                        <td>{{user.email}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Bucket :</th>
                        <td>{{user.directory}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Register From :</th>
                        <td>{{user.device}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Register At :</th>
                        <td>{{parseDate(user.date_join)}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Status :</th>
                        <td><span :class="`badge badge-pill ${user.isActive ? 'badge-soft-success' : 'badge-soft-warning'} font-size-11`">{{user.isActive ? "Active" : "Suspend"}}</span></td>
                    </tr>
                    <tr v-if="user.suspend">
                        <th scope="row">Reason :</th>
                        <td>{{user.suspend.reason}}</td>
                    </tr>
                    <tr v-if="user.suspend">
                        <th scope="row">Suspend At :</th>
                        <td>{{parseDate(user.suspend.date)}}</td>
                    </tr>
                    <tr v-if="user.stripe_id">
                        <th scope="row">Stripe Account ID :</th>
                        <td>{{user.stripe_id}}</td>
                    </tr>
                    <tr v-if="user.keys">
                        <th scope="row">Access Key :</th>
                        <td>{{user.keys.access_key}}</td>
                    </tr>
                    <tr v-if="user.keys">
                        <th scope="row">Secret Key :</th>
                        <td>{{user.keys.secret_key}}</td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            </div>
        </b-overlay>
        <!-- end card -->

        <b-overlay :show="loading" rounded="sm">
            <div class="card"  with overlay :aria-hidden="loading ? 'true' : null">
            <div class="card-body">
                <h4 class="card-title mb-4">Packages</h4>
                <div class="table-responsive mb-0">
                <table class="table">
                    <tbody>
                    <tr>
                        <th scope="row">Package Name :</th>
                        <td>{{user.packages.name_package}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Storage :</th>
                        <td>{{convertBytes(user.packages.basic.storage)}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Max Upload :</th>
                        <td>{{user.packages.basic.max_upload}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Max Workgroup :</th>
                        <td>{{user.packages.workgroup.max_workgroup}}</td>
                    </tr>
                    <tr>
                        <th scope="row">Max Participant :</th>
                        <td>{{user.packages.workgroup.max_participants}}</td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            </div>
        </b-overlay>
        <!-- end card -->
        <!-- <pre>
              {{invoices}}</pre> -->
      </div>

      <div class="col-xl-8">
        <div class="row">
          <div class="col-md-4">
            <b-overlay :show="loading" rounded="sm">
                <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                    <div class="card-body">
                        <div class="media">
                            <div class="media-body">
                                <p class="text-muted font-weight-medium">Folder</p>
                                <h4 class="mb-0">{{user.countFolder}}</h4>
                            </div>

                            <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                <span class="avatar-title">
                                    <i :class="`bx bx-folder font-size-24`"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
          </div>
          <div class="col-md-4">
            <b-overlay :show="loading" rounded="sm">
                <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                    <div class="card-body">
                        <div class="media">
                            <div class="media-body">
                                <p class="text-muted font-weight-medium">File</p>
                                <h4 class="mb-0">{{user.countFile}}</h4>
                            </div>

                            <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                <span class="avatar-title">
                                    <i :class="`bx bx-file-blank font-size-24`"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
          </div>
          <div class="col-md-4">
            <b-overlay :show="loading" rounded="sm">
                <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                    <div class="card-body">
                        <div class="media">
                            <div class="media-body">
                                <p class="text-muted font-weight-medium">Workgroup</p>
                                <h4 class="mb-0">{{user.countWorkgroup + user.countParticipant}}</h4>
                            </div>

                            <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                <span class="avatar-title">
                                    <i :class="`bx bx-file-blank font-size-24`"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
          </div>
          <div class="col-md-4">
            <b-overlay :show="loading" rounded="sm">
                <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                    <div class="card-body">
                        <div class="media">
                            <div class="media-body">
                                <p class="text-muted font-weight-medium">File Share</p>
                                <h4 class="mb-0">{{user.countShare}}</h4>
                            </div>

                            <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                <span class="avatar-title">
                                    <i :class="`bx bx-file-blank font-size-24`"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
          </div>
          <div class="col-md-4">
            <b-overlay :show="loading" rounded="sm">
                <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                    <div class="card-body">
                        <div class="media">
                            <div class="media-body">
                                <p class="text-muted font-weight-medium">Create Workgroup</p>
                                <h4 class="mb-0">{{user.countWorkgroup}}</h4>
                            </div>

                            <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                <span class="avatar-title">
                                    <i :class="`bx bx-file-blank font-size-24`"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
          </div>
          <div class="col-md-4">
            <b-overlay :show="loading" rounded="sm">
                <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                    <div class="card-body">
                        <div class="media">
                            <div class="media-body">
                                <p class="text-muted font-weight-medium">Invited Workgroup</p>
                                <h4 class="mb-0">{{user.countParticipant}}</h4>
                            </div>

                            <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                <span class="avatar-title">
                                    <i :class="`bx bx-file-blank font-size-24`"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
          </div>
        </div>

        <b-overlay :show="loadingInvoice" rounded="sm">
        <div class="card"   with overlay :aria-hidden="loadingInvoice ? 'true' : null">
          <div class="card-body">
            <h4 class="card-title mb-4">Invoice</h4>
            <div class="table-responsive mb-0">
              <table class="table table-nowrap table-hover">
                <thead>
                  <tr>
                    <th scope="col">Amount</th>
                    <th scope="col">Invoice Number</th>
                    <th scope="col">Description</th>
                    <th scope="col">Created</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="invoice in invoices.list" :key="invoice.id">
                    <td> $ {{invoice.total / 100}} {{invoice.currency}}</td>
                    <td><a :href="invoice.invoice_pdf">{{invoice.number}}</a></td>
                    <td>{{invoice.lines.data[0].description}}</td>
                    <td>{{parseTimeStamp(invoice.created)}}</td>
                    <td><span :class="`badge badge-pill ${invoice.paid ? 'badge-soft-success' : 'badge-soft-warning'} font-size-11`">{{invoice.paid ? "Paid" : "Failed"}}</span></td>
                  </tr>
                </tbody>
                <tfoot v-if="invoices.has_more">
                    <tr>
                        <td align="center" colspan="4">
                            <button class="btn btn-primary btn-sm" @click="moreInvoice(user.email)"> <i class="bx bx-chevron-down"></i> load more</button>
                        </td>
                    </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        </b-overlay>
        

        <!-- modal suspend -->
        <b-modal id="suspend" title="Please fill the reason, why user suspend" @ok="okeSuspend">
            <div class="form-group">
                <input type="text" class="form-control" placeholder="suspend reason" v-model="reason">
            </div>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>
